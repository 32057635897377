












































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import LionParcelLogo from '@/app/ui/assets/lion_parcel_logo.vue'
import ForgotPasswordSuccess from '@/app/ui/assets/forgot_password_success.vue'
import ForgotPasswordExpired from '@/app/ui/assets/forgot_password_expired.vue'

@Component({
  components: {
    Button,
    LionParcelLogo,
    ForgotPasswordSuccess,
    ForgotPasswordExpired,
  },
})
export default class ResetPasswordPage extends Vue {

  get status(): string {
    if (this.$route.query.message === 'The link has expired') {
      return 'expired'
    }

    return 'invalid'
  }
}
